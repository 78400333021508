.superBox {
  width: 100%;
  display: inline-block;
}

.imgInput {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.react-datepicker-wrapper {
  width: 100%;
}

.inputCt {
  position: relative;
  border: #d1d6dc solid 1px;
  border-radius: 5px;
  padding: 5px;
  outline-color: #1f2a37;
  padding-right: 30px;
}

/* Container DatePicker */
.boxCalendar {
  background-color: white;
  border-radius: 4px;
  padding: 5px;
  height: fit-content;
  box-shadow: #0003 0px 4px 7px;
}

/* DatePicker */
.react-datepicker {
  border: none !important;
}

/* Header */
.react-datepicker__header {
  background-color: unset !important;
  border: none !important;
}

/* Header text */
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 12px;
  color: #1f2a37;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #1f2a37;
}

/* Header line days texts */
.react-datepicker__day-names div {
  color: #6c7180 !important;
  margin-bottom: 5px;
}

/* date select */
.react-datepicker__day--selected {
  background-color: #1f2a37;
}

/* all date text style*/
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  /* color: #1f2a37; */
  font-weight: bolder !important;
}

/* time */

.react-datepicker__time {
  border-radius: 4px;
  padding: 5px;
  box-shadow: #0003 0px 4px 7px;
}

.react-datepicker__time-list-item {
  color: #1f2a37;
  font-weight: bolder !important;
}

