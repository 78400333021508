.containerDSH {
  display: flex;
  width: 100%;
  height: 100%;
}

.sideBar {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  position: relative;
  transition: 1s;
}

.btnCloseSidebar {
  display: none;
  height: 40px;
  position: absolute;
  z-index: 100;
  right: 20px;
  top: 20px;
}

.child {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.bar_side {
  display: none;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #eee;
}

.btnOpenSidebar {
  transform: rotate(90deg);
  letter-spacing: 1px;
  text-align: center;
  padding: 3px;
  cursor: pointer;
}

.header {
  width: 100%;
  height: 130px;
  min-height: 130px;
  max-height: 130px;
  border-bottom: solid 1px #eee;
  overflow: hidden;
  transition: 400ms;
}

.body {
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .container {
    flex-direction: column;
  }

  .sideBar {
    position: absolute;
    width: 100%;
    height: 100%;
    min-width: 100%;
    max-width: 100%;
    z-index: 50;
    box-shadow: #0005 0px 4px 10px;
    padding: 0px;
    overflow: hidden;
    transition: 400ms;
  }

  .header {
    height: 0px;
    max-height: 0px;
    min-height: 0px;
  }

  .header section {
    margin-top: 10px;
  }

  .btnCloseSidebar {
    display: block;
  }

  .bar_side {
    display: flex;
  }

  .imgBottomSidebar {
    display: none;
  }

  .breadcrumb {
    display: none;
  }
}
