/* Loader - Points rebondissants */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  position: relative;
}

.loader div {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: #1F2A37; /* Couleur des points */
  border-radius: 50%;
  animation: bounce 1.5s infinite ease-in-out;
}

.loader div:nth-child(1) {
  animation-delay: -0.3s;
}

.loader div:nth-child(2) {
  animation-delay: -0.15s;
}

.loader div:nth-child(3) {
  animation-delay: 0;
}

/* Animation de rebond */
@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
