.tableContainer {
  border-spacing: 0;
  margin-left: 7px;
  margin-right: 7px;
  margin-top: 7px;
}
.tableContainer thead tr {
  background-color: #0369a1;
  color: white;
}
.tableContainer th {
  padding: 5px;
  border: 1px solid #0369a1;
  font-size: 11px;
}
.tableContainer td {
  padding: 5px;
  border: 1px solid gray;
  font-size: 9px;
}

.animTrash :hover {
  transition: 200ms;
  transform: scale(2);
}

