.hoverText {
  cursor: pointer;
  width: fit-content;
}

.boxHover {
  position: relative;
}

.boxInfos {
  position: absolute;
  background-color: #c5654edd;
  left: 15px;
  top: 25px;
  border-radius: 7px;
  overflow: hidden;
  transition: 500ms;

  width: 0px;
  padding: 0px;
}

.boxInfos ul li {
  text-shadow: 1px 1px 4px #00000078;
}

.boxInfos ul li div {
  display: flex;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 5px;
  align-items: center;
  color: white;
}

.boxInfos ul li span {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 5px;
}

.hoverText:hover ~ div .boxInfos {
  padding: 4px;
  height: auto;
  width: 300px;
  box-shadow: 1px 1px 3px #3c3c3ca3;
}

